import React, {useLayoutEffect, useState} from "react"
import { Parallax, ParallaxLayer } from '../components/Parallax';
import Layout from "../components/Layout";
import TitleCard from "../components/TitleCard";
import StandardScrollSection from "../components/StandardScrollSection";
import ReviewsStars from "../components/ReviewsStars";
import Ending from "../components/Ending";
import EndingMenuItem from "../components/EndingMenuItem";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { SEO } from "../components/SEO";

import {chaseGoldSlide, dropInDrop, showOutAppear, chopItUpType, victorsAppear, comeCloser} from "../helpers/animations";

const LoversPage = () => {

  const [color, setColor] = useState("blue");
  const [itineraryLinkVisible, setitineraryLinkVisible] = useState(true);

  const wellnessLink = "https://visitsunvalley.com/wellness/wellness-getaway-to-sun-valley-idaho/";
  const shoppingLink = "https://visitsunvalley.com/things-to-do/shopping/";
  const darkSkyLink = "https://visitsunvalley.com/winter/the-dark-skies-of-sun-valley-id/"
  const knobHillLink = "https://visitsunvalley.com/lodging/knob-hill-inn/"
  const roundHouseLink = "https://visitsunvalley.com/dining-shopping/the-roundhouse-averells/"

  const title = <>Welcome to<br/>Sun Valley, ID</>
  const formId = "5B5E7037DA78A748374AD499497E309E2A4511AE8B07EFA434D0B0E02D8CAD23D4BBC055519E38B2D608E97086A0AFF6097DA00E57B349100FAB7149477A21BC";
  const emailName = "cm-bdtakj-bdtakj";
  const itineraryLink = "/VSV_Winter_Itinerary_Lovers.pdf";
  const subTitle = "Scroll sideways to plan your couples getaway today."
  const altTitle = <>Lovers<br/>On High</>

  return (
      <Layout color={color} itineraryLinkVisible={itineraryLinkVisible} itineraryLink={itineraryLink} formId={formId} emailName={emailName}>
        <Parallax pages={17.7} style={{ top: '0', left: '0' }}>
          <ParallaxLayer offset={0} speed={1}>
            <TitleCard altTitle={altTitle} title={title} altTitle={altTitle} instructions={subTitle} imgSrc="/VSV_LandingPage_Lovers_01.jpg" />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={1}>
            <div className="ending-menu toc">
              <EndingMenuItem text="Spa" scrollTo="spa" duration=".5"/>
              <EndingMenuItem text="Shop" scrollTo="shop" duration="1"/>
              <EndingMenuItem text="Stargaze" scrollTo="stars" duration="1.5"/>
              <EndingMenuItem text="Lodge" scrollTo="lodge" duration="2"/>
              <EndingMenuItem text="Feast" scrollTo="feast" duration="2.5"/>
            </div>
          </ParallaxLayer>

          <StandardScrollSection
            id="spa"
            offset={1.5}
            setNavColor={setColor}
            bgColor="brown"
            titleColor="white"
            title={<>GET AWAY</>}
            content={<>The ultimate romantic getaway starts with a <OutboundLink href={wellnessLink} target="_blank">day of wellness.</OutboundLink> Snowshoe across the Valley. Massage your tired muscles. Let the fresh mountain air invigorate your soul. It’s long overdue.</>}
            intoImg='/VSV_LandingPage_Lovers_11.jpg'
            backgroundSvg='/lovers_bg_01.svg'
            foregroundImg='/VSV_Winter_Lovers_Getaway_Isolated.png'
            trailImg='/VSV_Winter_Lovers_Getaway.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"10%", y:0, scale:.7}, {x:"-40%", y:"-20%", ease: "none"})
            }}
            titleAnimationFunc={chaseGoldSlide}
            />

          <StandardScrollSection
            id="shop"
            offset={4.5}
            setNavColor={setColor}
            bgColor="red"
            titleColor="blue"
            title={<>HAVE<br/>A DAY</>}
            content={<><OutboundLink href={shoppingLink} target="_blank">Shopping</OutboundLink> in a place as storied as Sun Valley almost always leads to unexpected gems. A first-edition signed by Hemingway? An EAT MORE LAMB t-shirt? When you return home, you’ll be the envy of your friend's eyes.</>}
            intoImg='/VSV_Winter_Lovers_Haveaday_01.jpg'
            backgroundSvg='/lovers_bg_02.svg'
            foregroundImg='/VSV_Winter_Lovers_Haveaday_Isolated.png'
            trailImg='/VSV_Winter_Lovers_Haveaday_02.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"-50%", y:"10%"}, {x:"-10%", y:"-10%", ease: "none"})
            }}
            titleAnimationFunc={dropInDrop}
            />

          <StandardScrollSection
            id="stars"
            offset={7.5}
            setNavColor={setColor}
            bgColor="yellow"
            titleColor="red"
            title={<>CRADLE<br/>THE COSMOS</>}
            content={<>The city of Ketchum was recently designated an <OutboundLink href={darkSkyLink} target="_blank">International Dark Sky Community.</OutboundLink> Which means it’s one of the best places in the world to sip Cabernet under the Milky Way.</>}
            intoImg='/VSV_LandingPage_Lovers_08.jpg'
            backgroundSvg='/lovers_bg_03.svg'
            foregroundImg='/VSV_LandingPage_Lovers_09.png'
            trailImg='/VSV_LandingPage_Lovers_10.jpg'
            imageAnimationFunc={(imageRef)=>(tl)=>{
              tl.fromTo(imageRef.current, {x:"-85%", scale: 1.5, y: "-8.5%", ease: "none"}, {x:"0%"})
            }}
            titleAnimationFunc={showOutAppear}
            />

            <StandardScrollSection
              id="lodge"
              offset={10.5}
              setNavColor={setColor}
              bgColor="blue"
              titleColor="yellow"
              title={<>COME<br/>CLOSER</>}
              content={<>In search of majestic views and some serious European chalet charm? <OutboundLink href={knobHillLink} target="_blank">The Knob Hill Inn</OutboundLink> is your spot. Guests are welcomed like family here by staff who take pride in the region and deliver a super personalized stay.</>}
              intoImg='/VSV_LandingPage_Lovers_02.jpg'
              backgroundSvg='/lovers_bg_04.svg'
              foregroundImg='/VSV_LandingPage_Lovers_03.png'
              trailImg='/VSV_LandingPage_Lovers_04.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .65, y: "0%", x:"-40%", ease: "none"}, { scale: 1.5, y: "-10%",  ease: "none"})
              }}
              titleAnimationFunc={comeCloser}
              />

            <StandardScrollSection
              id="feast"
              offset={13.5}
              setNavColor={setColor}
              bgColor="red"
              titleColor="brown"
              title={<>DINE AT<br/>7700 FEET</>}
              content={<>Accessible only by gondola, <OutboundLink href={roundHouseLink} target="_blank">The Roundhouse</OutboundLink> has been serving up memorable meals and romantic vistas since 1939. Try the fondue with a dry Riesling. And finally do that date night you’ve been planning for months.</>}
              intoImg='/VSV_LandingPage_Lovers_05.jpg'
              backgroundSvg='/lovers_bg_05.svg'
              foregroundImg='/VSV_LandingPage_Lovers_06.png'
              trailImg='/VSV_LandingPage_Lovers_07.jpg'
              imageAnimationFunc={(imageRef)=>(tl)=>{
                tl.fromTo(imageRef.current, { scale: .7, y: "20%", x:"-25%", ease: "none"}, { scale: 1, y: "-30%", ease: "none"})
              }}
              titleAnimationFunc={victorsAppear}
              />

            <ReviewsStars offset={16.5} rating={4.5} />

        </Parallax>
      </Layout>
    );
}

export default LoversPage

export const Head = () => (
  <SEO title="Lovers, Sun Valley Wants You" image="./SEO_VSV_LandingPage_Lovers_01.jpg" description="Are you a couple in search of a romantic getaway? Sun Valley, ID wants you."/>
)
